import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { Header } from "../../components/Header";
import { ProjectGroupTag } from "../../components/ProjectGroupTags/ProjectGroupTag";
import { ProjectPreview } from "../../components/ProjectPreview";
import { useLanguage } from "../../hooks/useLanguage";
import useQueries from "../../hooks/useQueries";
import { Enum_Projectgroups_Type, ProjectGroups, Projects } from "../../models/types";
import { useGlobalStore } from "../../store/globalStore";
import translations from "../../translations/constants";

export const ProjectsRoute = () => {
    const intl = useIntl();
    const [language] = useLanguage();
    const previousProjectGroupSlug = useGlobalStore((state) => state.previousProjectGroupSlug);
    const navigate = useNavigate();

    const { groupSlug } = useParams();

    const { allProjectsQuery, allProjectGroupsQuery } = useQueries();

    const navigateToGroupSlug = (groupSlug?: string, replace?: boolean) => {
        if (groupSlug) {
            navigate(`/${language}/projekt/mit/${groupSlug}`, {
                replace,
            })
        } else {
            navigate(`/${language}/projekt`, {
                replace,
            })
        }
    }

    useEffect(() => {
        if (previousProjectGroupSlug && !groupSlug) {
            //we don't have a slug at the moment and have a previous slug
            navigateToGroupSlug(previousProjectGroupSlug)

            useGlobalStore.setState({
                previousProjectGroupSlug: undefined
            })
        } else if (previousProjectGroupSlug && groupSlug) {
            //we have a slug but also a previous slug
            useGlobalStore.setState({
                previousProjectGroupSlug: undefined
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [selectedProjectGroup, setSelectedProjectGroup] = useState<ProjectGroups | null>(null)
    const [filteredProjects, setFilteredProjects] = useState<Projects[] | undefined>(undefined)

    useEffect(() => {
        if (allProjectGroupsQuery.data) {
            if (groupSlug) {
                const selectedGroup = allProjectGroupsQuery.data.find((group) => group.slug === groupSlug)
                if (selectedGroup) {
                    setSelectedProjectGroup(selectedGroup)
                    return;
                }
            }
        }
        setSelectedProjectGroup(null)
    }, [groupSlug, allProjectGroupsQuery.data])


    useEffect(() => {
        if (allProjectsQuery.data) {
            const allProjects = allProjectsQuery.data;

            setFilteredProjects(selectedProjectGroup === null ? allProjects : allProjects.filter((project) => {
                if (project.groups === null || project.groups?.length === 0) return false;

                return project.groups!.some((group) => group?.id === selectedProjectGroup!.id);
            }))
        }
    }, [allProjectsQuery.data, selectedProjectGroup])

    const onClickFilter = (group: ProjectGroups) => {
        if (selectedProjectGroup === null || selectedProjectGroup?.id !== group.id) {
            navigateToGroupSlug(group.slug, true)
            useGlobalStore.setState({
                previousProjectGroupSlug: group.slug
            })
            setSelectedProjectGroup(group);
        } else {
            navigateToGroupSlug(undefined, true);
            useGlobalStore.setState({
                previousProjectGroupSlug: undefined
            })
        }
    }

    return (
        <div className="flex flex-col gap-4">
            <Helmet>
                <title>{selectedProjectGroup === null ? intl.formatMessage(translations.components.projects.projectsMetaTitle) : intl.formatMessage(translations.components.projects.projectsWithMetaTitle, {
                    groupTitle: selectedProjectGroup!.filterTitle
                })}</title>
            </Helmet>

            {selectedProjectGroup === null && <Header level={1} firstLetterColor="primary" suffix={{ content: ".", color: "primary" }}>{intl.formatMessage(translations.components.projects.listTitle)}</Header>}
            {selectedProjectGroup !== null && <Header level={1} firstLetterColor="primary" suffix={{ content: ".", color: "secondary" }}>{intl.formatMessage(translations.components.projects.listTitleWithFilter)}{" "}<span className="text-primary-default">{selectedProjectGroup!.filterTitle}</span></Header>}

            <Header level={3} >
                {intl.formatMessage(translations.components.projects.filterBy)}
            </Header>

            <div>
                {allProjectGroupsQuery.isSuccess && <div className="-m-1">
                    {allProjectGroupsQuery.data.sort((a, b) => {
                        if (a.type === Enum_Projectgroups_Type.Primary && b.type !== Enum_Projectgroups_Type.Primary) return -1;
                        if (a.type !== Enum_Projectgroups_Type.Primary && b.type === Enum_Projectgroups_Type.Primary) return 1;

                        return a.title.localeCompare(b.title);
                    })
                        .map((group) => (
                            <ProjectGroupTag key={group.id} group={group} highlight={selectedProjectGroup !== null && selectedProjectGroup!.id === group.id} enableModalIcon onClick={() => { onClickFilter(group) }} />
                        ))}
                </div>}
            </div>

            <div className="mb-12">
                {filteredProjects && (
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 lg:gap-12">
                        {filteredProjects.map((project) => (
                            <ProjectPreview key={project.id} project={project} mode="list" />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};
