import { PropsWithChildren, ReactNode } from "react";
import { useIntl } from "react-intl";
import translations from "../../translations/constants";
import { Button } from "../Button";

type Props = {
    isOpen: boolean;
    onClose: () => void;
    title?: string;
    actions?: ReactNode;
    withCancelButton?: boolean;
    cancelOnOutsideClick?: boolean;
} & PropsWithChildren;

export const Modal = (props: Props) => {
    const intl = useIntl();

    return (<div className="relative flex justify-center">

        {props.isOpen && <div
            // x-transition:enter="transition duration-300 ease-out"
            // x-transition:enter-start="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
            // x-transition:enter-end="translate-y-0 opacity-100 sm:scale-100"
            // x-transition:leave="transition duration-150 ease-in"
            // x-transition:leave-start="translate-y-0 opacity-100 sm:scale-100"
            // x-transition:leave-end="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
            className="fixed inset-0 z-30 overflow-y-auto"
            aria-labelledby="modal-title" role="dialog" aria-modal="true"
            onClick={(e) => {
                if ((e.target as any).className.includes("modal")) {
                    props.onClose();
                }
            }}
        >
            <div className="modal flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0 bg-black/50">
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl rtl:text-right dark:bg-gray-900 sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                    {props.title !== undefined && <h3 className="font-medium text-2xl mb-2 leading-6 text-gray-800 capitalize dark:text-gray-200">
                        {props.title}
                    </h3>}

                    <div>
                        {props.children}
                    </div>

                    <div className="mt-4 sm:flex sm:items-center sm:justify-end">
                        {props.withCancelButton && <Button color="gray" type="outline" onClick={() => props.onClose()}>{intl.formatMessage(translations.components.generic.close)}</Button>}

                        {props.actions}
                    </div>
                </div>
            </div>
        </div>}

    </div>)
}