import { ReactNode, useEffect, useRef, useState } from "react";

type Props = {
    children: ReactNode[];
}

export const ProjectCarousel = (props: Props) => {
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const sliderRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const sliderCurrent = sliderRef.current;

        if (!sliderCurrent) {
            return;
        }

        // Find all the slides inside of the slider
        const slides = sliderCurrent.querySelectorAll("div");
        const slidesArray = Array.from(slides);

        // Wait until a slide is 50% visible, then find it's index in the array of
        // all slides and update the currentSlideIndex
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const index = slidesArray.indexOf(entry.target as HTMLDivElement);
                        setCurrentSlideIndex(index);
                    }
                });
            },
            {
                root: sliderCurrent,
                threshold: 0.5,
            }
        );
        slides.forEach((slide) => observer.observe(slide));

        return () => slides.forEach((slide) => observer.unobserve(slide));
    }, []);

    const decreaseImage = () => {
        if (currentSlideIndex <= 0) return;

        sliderRef.current?.children[currentSlideIndex - 1].scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start",
        })

        setCurrentSlideIndex(currentSlideIndex - 1);
    };

    const increaseImage = () => {
        if (currentSlideIndex >= props.children.length - 1) return;

        sliderRef.current?.children[currentSlideIndex + 1].scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start",
        })

        setCurrentSlideIndex(currentSlideIndex + 1);
    };

    return (<div className="relative w-full">
        <div className="absolute top-0 left-0 w-full h-full flex items-end justify-center">
            {currentSlideIndex > 0 && <div className="absolute left-0 z-20 cursor-pointer top-0 w-1/6 h-full flex items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none" onClick={decreaseImage}>
                <span className="inline-block h-8 w-8">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="h-6 w-6">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 19.5L8.25 12l7.5-7.5" />
                    </svg>
                </span>
                <span
                    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                >Previous</span>
            </div>}

            {currentSlideIndex < props.children.length - 1 && <div className="absolute right-0 cursor-pointer z-20 top-0 w-1/6 h-full flex items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none" onClick={increaseImage}>
                <span className="inline-block h-8 w-8">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="h-6 w-6">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                </span>
                <span
                    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                >Next</span >
            </div>}
        </div>

        <div
            ref={sliderRef}
            className="w-full h-96 flex flex-row overflow-x-scroll snap-x snap-mandatory"
            style={{
                clipPath: "inset(0 0 15px 0)",
            }}
        >
            {props.children.map((child, index) => {
                return <div key={index} className="w-full flex-shrink-0 snap-start">
                    {child}
                </div>
            })}
        </div>

        <div className="absolute bottom-[30px] z-30 w-full flex items-center justify-center">
            {props.children.map((child, index) => {
                return <div
                    key={index} style={{
                        transition: "backgroundColor 150ms ease-in-out",
                    }} className={`w-3 h-3 shadow-sm rounded-full bg-gray-200 mx-1 ${currentSlideIndex === index ? 'bg-primary-default' : ''}`} />
            })}
        </div>
    </div>)
}