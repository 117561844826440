import { useEffect, useMemo, useState } from "react";
import { Button } from "./Button";
import { Header } from "./Header";
import { Link } from "./Link";
import { Text } from "./Text";

export const ConsentBanner = () => {
    const [localConsent, setLocalConsent] = useState<boolean | undefined>(undefined);
    const consent = useMemo(() => {
        if (localConsent === undefined) {
            return localStorage.getItem('analyticsConsent') === "true" ? true : localStorage.getItem('analyticsConsent') === "false" ? false : undefined;
        } else {
            return localConsent;
        }
    }, [localConsent]);

    useEffect(() => {
        if (localConsent !== undefined) {
            localStorage.setItem("analyticsConsent", localConsent ? "true" : "false");

            if (localConsent === true) {
                // @ts-ignore
                gtag('consent', 'update', {
                    'analytics_storage': 'granted',
                });
            } else if (localConsent === false) {
                // @ts-ignore
                gtag('consent', 'update', {
                    'ad_storage': 'denied',
                    'ad_user_data': 'denied',
                    'ad_personalization': 'denied',
                    'analytics_storage': 'denied'
                });
            }
        }
    }, [localConsent]);



    return <>
        {consent === undefined && <div className="fixed z-50 left-0 bottom-0 right-0 max-h-full px-4 md:px-8 xl:px-32 pb-4 md:pb-8 xl:pb-16">
            <div className="h-full max-w-screen-xl mx-auto bg-white shadow-md rounded-md p-4 xl:p-8 flex flex-col xl:flex-row gap-4">
                <div className="w-full xl:w-[75%] h-full flex flex-col gap-4">
                    <Header level={1}>
                        Cookies & Tracking
                    </Header>

                    <Text level={"larger"} className="text-left">
                        Ich nutze Google Analytics Tracking, um anonymisierte Nutzerdaten zu erfassen und die Nutzung meiner Seite zu analysieren. Dabei werden diese Daten <b>nicht</b> zu Werbezwecke.
                        Weitere Informationen finden Sie in der <Link to="/privacy">Datenschutzerklärung</Link>.
                    </Text>
                </div>

                <div className="w-full xl:w-[25%] flex flex-col justify-end">
                    <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-1">
                        <Button color="primary" type="outline" onClick={() => setLocalConsent(false)}>Ablehnen</Button>
                        <Button color="primary" type="default" onClick={() => setLocalConsent(true)}>Akzeptieren</Button>
                    </div>
                </div>
            </div>
        </div>}
    </>
}