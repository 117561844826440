import { LinkProps, Link as ReactLink, useParams } from "react-router-dom";

type Props = {} & LinkProps;

export const Link = (props: Props) => {
    const { to } = props;
    const params = useParams();

    const lang = params.lang || "de";

    return <ReactLink {...props} preventScrollReset={false} to={`/${lang}${to}`} onClick={() => window.scrollTo({ top: 0 })} />;
}