import { useCallback, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export const useLanguage = () => {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const language = useMemo(() => params.lang || "de", [params.lang])
    const setLanguage = useCallback((newLanguage: "de" | "en") => {
        const currentPath = location.pathname;

        //as we have an automatic redirect whenever a user accesses the page without a language code
        //we don't need to worry about the language code existing or not
        //we can simply replace the language code in the URL

        const newPath = currentPath.replace(`/${language}`, `/${newLanguage}`);

        navigate(newPath, {
            replace: true,
        });
    }, [location, navigate, language])

    return [language, setLanguage] as const;
}