import React, { PropsWithChildren } from "react";

type Props = {
    firstLetterColor?: "primary" | "secondary";
    suffix?: {
        content: React.ReactNode;
        color?: "primary" | "secondary";
    },
    className?: string;
    level: 1 | 2 | 3 | 4 | 5 | 6;
    showLoading?: boolean;
} & PropsWithChildren;

export const Header = (props: Props) => {

    const size = props.level === 1 ? "text-3xl lg:text-4xl" : props.level === 2 ? "text-2xl lg:text-3xl" : props.level === 3 ? "text-xl lg:text-2xl" : props.level === 4 ? "text-lg lg:text-xl" : props.level === 5 ? "text-base lg:text-lg" : "text-sm lg:text-base";

    const firstLetterColor = props.firstLetterColor ? props.firstLetterColor === "primary" ? "first-letter:text-primary-default " : "first-letter:text-secondary-default " : "";

    return <h1 className={`font-semibold text-gray-800 dark:text-white tracking-wide ${size} ${firstLetterColor} ${props.className ?? ""}`}>
        {props.children}
        {props.suffix && <span className={`${props.suffix.color ? props.suffix.color === "primary" ? "text-primary-default" : "text-secondary-default" : ""}`}>{props.suffix.content}</span>}
    </h1>
}