import { defineMessages } from "react-intl";

export const error = defineMessages({
    error404Title: {
        id: "components.error.error-404-title",
        defaultMessage: "404",
        description: "The title of the 404 error page"
    },
    error404Subtitle: {
        id: "components.error.error-404-subtitle",
        defaultMessage: "Page not found",
        description: "The subtitle of the 404 error page"
    },
    error404Description: {
        id: "components.error.error-404-description",
        defaultMessage: "The page you are looking for does not exist.",
        description: "The description of the 404 error page"
    },
    error404BackButton: {
        id: "components.error.error-404-back-button",
        defaultMessage: "Go Back",
        description: "The text of the back button"
    },
    error404HomeButton: {
        id: "components.error.error-404-home-button",
        defaultMessage: "Take me home",
        description: "The text of the home button"
    },
    error404InterestingProjectTitle: {
        id: "components.error.error-404-interesting-project-title",
        defaultMessage: "Interesting project",
        description: "The title of the interesting project section"
    }
})