import React from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import translations from "../../translations/constants";
import { NavbarLogo } from "./NavbarLogo";
import { NavbarMenu } from "./NavbarMenu";

export const Navbar = () => {
    const intl = useIntl();

    const [isOpen, setIsOpen] = React.useState(false);

    return (
        <nav className="relative below_lg:fixed z-50 w-full bg-white shadow dark:bg-gray-800">
            <div className="relative container px-6 py-4 mx-auto lg:flex lg:justify-between lg:items-center">
                <div className="flex items-center justify-between">
                    <div onClick={() => setIsOpen(false)}>
                        <NavbarLogo />
                    </div>

                    <Helmet>
                        <title>{intl.formatMessage(translations.components.home.homeMetaTitle)}</title>
                        <meta name="description" content={intl.formatMessage(translations.components.home.homeMetaDescription)} />
                    </Helmet>

                    <div className="flex lg:hidden">
                        <button
                            x-type="button"
                            className="text-gray-500 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400 focus:outline-none focus:text-gray-600 dark:focus:text-gray-400"
                            aria-label="toggle menu"
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            {!isOpen && (
                                <svg
                                    x-show="!isOpen"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-6 h-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 8h16M4 16h16" />
                                </svg>
                            )}

                            {isOpen && (
                                <svg
                                    x-show="isOpen"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-6 h-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            )}
                        </button>
                    </div>
                </div>

                <div
                    onClick={(e) => {
                        if ((e.target as HTMLElement).tagName.toLowerCase() === "a") {
                            setIsOpen(false);
                        }

                        if ((e.target as HTMLElement).id === "menu-background") {
                            setIsOpen(false);
                        }
                    }}
                    id="menu-background"
                    className={
                        (isOpen ? "translate-x-0 opacity-100 shadow" : "opacity-0 -translate-x-full ") +
                        " absolute inset-x-0 z-20 sm:rounded-e-xl sm:rounded-es-xl transition-transform duration-300 ease-in-out mt-4 lg:mt-0 lg:p-0 lg:top-0 lg:relative lg:bg-transparent lg:w-auto lg:opacity-100 lg:translate-x-0 lg:flex lg:items-center lg:flex-grow below_lg:h-screen bg-gray-800/80 w-[200vw]"
                    }
                >
                    <div className="flex flex-col lg:flex-row bg-white dark:bg-gray-800 w-full lg:justify-end">
                        <div className="p-4 below_lg:w-screen">
                            <div className="below_lg:hidden flex flex-row items-center justify-end">
                                <NavbarMenu mode="mobile" />
                            </div>

                            <div className="lg:hidden flex flex-col gap-4 w-full h-fit">
                                <NavbarMenu mode="default" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};
