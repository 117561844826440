import { defineMessages } from "react-intl";

export const home = defineMessages({
    homeMetaTitle: {
        id: "components.home.meta-title",
        defaultMessage: "Event Technology Aachen Finn Tegeler",
        description: "The meta title for the home page"
    },
    homeMetaDescription: {
        id: "components.home.meta-description",
        defaultMessage: "Together we will make your event a success. Concerts, theater performances, conferences or parties: For every event I support you in planning, communication with other companies, as well as in light and sound technology. Feel free to look around on my website and get in touch with me today.",
        description: "The meta description for the home page"
    },
    lightingTitle: {
        id: "components.home.lighting-title",
        defaultMessage: "Lighting",
        description: "Home title for lighting"
    },
    soundTitle: {
        id: "components.home.sound-title",
        defaultMessage: "Sound",
        description: "Home title for sound"
    },
    planningTitle: {
        id: "components.home.planning-title",
        defaultMessage: "Planning",
        description: "Home title for planning"
    },
    consultingTitle: {
        id: "components.home.consulting-title",
        defaultMessage: "Consulting",
        description: "Home title for consulting"
    },
    projectsButton: {
        id: "components.home.projects-button",
        defaultMessage: "My Projects",
        description: "The text of the projects button"
    },
    contactButton: {
        id: "components.home.contact-button",
        defaultMessage: "Contact Me",
        description: "The text of the contact button"
    },
    servicesTitle: {
        id: "components.home.services-title",
        defaultMessage: "My Services",
        description: "The title of the services section"
    },    serviceDescription: {
        id: "components.home.service-description",
        defaultMessage: "I offer a variety of services to make your event a success. From lighting to sound and planning to consulting, I can help you with all your needs. Here is an overview of all services I offer.",
        description: "The description of the services section"
    },
    servicesButton: {
        id: "components.home.services-button",
        defaultMessage: "All My Services",
        description: "The text of the services button"
    },
    testimonialsTitle: {
        id: "components.home.testimonials-title",
        defaultMessage: "Testimonials",
        description: "The title of the testimonials section"
    },
    testimonialsDescription: {
        id: "components.home.testimonials-description",
        defaultMessage: "Read what my clients have to say about my work and how I helped them make their event a success. I am looking forward to hearing from you and adding your testimonial to my website.",
        description: "The description of the testimonials section"
    },
    testimonialsButton: {
        id: "components.home.testimonials-button",
        defaultMessage: "More Projects & Testimonials",
        description: "The text of the testimonials button"
    },
});