
import { useImage } from "../../hooks/useImage";
import { Enum_Projectgroups_Type, Projects } from "../../models/types";
import { Link } from "../Link";
import { ProjectGroupTag } from "../ProjectGroupTags/ProjectGroupTag";

type Props = {
    project: Projects;
    mode: "list" | "service";
};

export const ProjectPreview = (props: Props) => {
    const { project } = props;

    const { getImageUrl } = useImage();

    return (
        <div className="flex flex-col  w-full mx-auto">
            <div className="flex flex-col items-center justify-center w-full">
                <Link to={`/projekt/${project.slug}`} className={`w-full ${props.mode === "list" ? "h-96" : "h-64"} bg-gray-300 bg-center bg-cover rounded-lg shadow-md cursor-pointer`}
                    style={{
                        backgroundImage: `url('${getImageUrl(project.titleImage!, "large")}')`,
                    }} />

                <div className={`w-3/4 below_lg:w-[90%] ${props.mode === "list" ? "-mt-14" : "-mt-7 below_lg:-mt-10"} overflow-hidden bg-white rounded-lg shadow-lg dark:bg-gray-800`}>
                    <p className="p-4 font-bold text-center text-gray-800 uppercase dark:text-white">{project.title}</p>

                    {props.mode === "list" && <div className="flex items-center justify-between px-6 py-4 bg-gray-200 dark:bg-gray-700">
                        <div className="flex items-center justify-start gap-1 flex-wrap">
                            {(project.groups || [])
                                .filter(
                                    (group) =>
                                        group !== undefined &&
                                        group !== null,
                                )
                                .sort((a, b) => {
                                    const groupA = a!;
                                    const groupB = b!;

                                    if (groupA.type === groupB.type) return groupA.title.localeCompare(groupB.title);
                                    if (groupA.type === Enum_Projectgroups_Type.Primary) return -1;
                                    return 1;
                                })
                                .map((maybeGroup) => {
                                    const group = maybeGroup!;

                                    return <div key={group.id} className="-m-1"><ProjectGroupTag group={group} /></div>;
                                })}
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    );
};
