import { UseQueryResult } from "@tanstack/react-query";
import { useEffect } from "react";
import useQueries from "./useQueries";

export const usePreloadQueries = () => {
    const queries = useQueries();

    useEffect(() => {
        const run = async () => {
            for (const query of Object.values(queries) as UseQueryResult[]) {
                if ((!query.isFetched || query.isStale) && query.isPaused) {
                    query.refetch();
                }
            }
        }
        run();
    }, [queries])
}