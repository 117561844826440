import React, { useEffect } from "react";
import { styles } from "../../../styles/constants";
import { MoonIcon } from "../../Icon/MoonIcon";
import { SunIcon } from "../../Icon/SunIcon";

export const DarkModeButton = () => {
    const [currentDarkMode, setCurrentDarkMode] = React.useState<"light" | "dark" | "auto" | undefined>(undefined);

    const setDarkMode = (mode: "light" | "dark" | "auto") => {
        localStorage.theme = mode;

        console.log("Setting dark mode to " + mode);

        if (mode === "light") {
            document.documentElement.classList.remove("dark");

            setCurrentDarkMode("light");
        } else if (mode === "dark") {
            document.documentElement.classList.add("dark");

            setCurrentDarkMode("dark");
        } else {
            localStorage.removeItem("theme");

            setCurrentDarkMode("auto");
        }
    };

    const onClick = () => {
        if (currentDarkMode === undefined) {
            setDarkMode("dark");
        } else if (currentDarkMode === "auto") {
            setDarkMode("light");
        } else if (currentDarkMode === "dark") {
            setDarkMode("light");
        } else if (currentDarkMode === "light") {
            setDarkMode("dark");
        }
    };
    useEffect(() => {
        if (localStorage.theme) {
            //we have a customs option set

            setCurrentDarkMode(localStorage.theme as "light" | "dark" | "auto");
        }
    }, []);

    return (
        <button
            className={`group flex border rounded-lg h-10 w-10 items-center justify-center ${styles.secondaryActionButtons}`}
            onClick={onClick}
            aria-label="Website Design zwischen Dunkel und Hell ändern"
        >
            {(currentDarkMode === "light" || currentDarkMode === undefined) && (
                <div className="relative w-5 h-5">
                    <MoonIcon />
                </div>
            )}
            {(currentDarkMode === "dark" || currentDarkMode === "auto") && (
                <div className="relative w-5 h-5">
                    <SunIcon />
                </div>
            )}
        </button>
    );
};
