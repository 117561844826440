import { useQuery } from "@tanstack/react-query"
import Markdown from "react-markdown"
import remarkGfm from "remark-gfm"
import strapi from "../../api"
import { Header } from "../../components/Header"
import { Privacy } from "../../models/types"

export const PrivacyRoute = () => {
    const privacyQuery = useQuery({
        queryKey: ["privacy"],
        queryFn: async () => {
            return await strapi.find<Privacy>("privacy", {
                _locale: "de",
            })
        },
    })

    return <div className="">
        {privacyQuery.isLoading && <p>Lade...</p>}

        {privacyQuery.isError && <p>Fehler bei der Abfrage</p>}

        {privacyQuery.isSuccess && privacyQuery.data && (
            <Markdown remarkPlugins={[remarkGfm]} components={{
                h1: ({ children }) => <Header level={1} className="mt-2">{children}</Header>,
                h2: ({ children }) => <Header level={2} className="mt-2">{children}</Header>,
                h3: ({ children }) => <Header level={3} className="mt-2">{children}</Header>,
                h4: ({ children }) => <Header level={4} className="mt-2">{children}</Header>,
                h5: ({ children }) => <Header level={5} className="mt-2">{children}</Header>,
                h6: ({ children }) => <Header level={6} className="mt-2">{children}</Header>,
                a: ({ children, href }) => <a href={href} className="text-secondary-default hover:text-secondary-light transition-colors">{children}</a>,
                li: ({ children }) => <li className="list-disc">{children}</li>,
                ul: ({ children }) => <ul className="list-inside">{children}</ul>,
            }}>
                {privacyQuery.data.content}
            </Markdown>
        )}
    </div>
}