import { useCallback } from "react";
import { Maybe, UploadFile } from "../models/types";

type FormatKeys = "thumbnail" | "small" | "medium" | "large";

const sizeToIndex = {
    thumbnail: 0,
    small: 1,
    medium: 2,
    large: 3,
}

export const useImage = () => {
    const getImageUrl = useCallback((image: Maybe<UploadFile>, size: FormatKeys | "full" = "medium") => {
        if (!image) return undefined;

        const baseUrl = process.env.REACT_APP_API_URL

        // console.log(`Requesting image ${image.name} with size ${size} and ${Object.keys(image.formats).length} available formats`)

        if (size === "full") return `${baseUrl}${image.url}`;

        const availableFormats: FormatKeys[] = Object.keys(image.formats) as FormatKeys[];

        if (availableFormats.includes(size)) {
            // console.log(`Returning image ${image.name} with requested size ${size}`)
            return `${baseUrl}${image.formats[size].url}`;
        }

        //we don't have the requested size, so we need to see if we got any higher ones
        const sizeIndex = sizeToIndex[size];
        const availableFormatsThatAreHigher = availableFormats.filter(format => sizeToIndex[format] > sizeIndex);

        if (availableFormatsThatAreHigher.length > 0) {
            const lowestAvailableFormatThatIsHigher = availableFormatsThatAreHigher.sort((a, b) => sizeToIndex[a] - sizeToIndex[b])[0];

            // console.log(`Returning image ${image.name} with lowest available format that is higher then request ${lowestAvailableFormatThatIsHigher}`)

            return `${baseUrl}${image.formats[lowestAvailableFormatThatIsHigher].url}`;
        }

        //we don't have any higher ones, so we will try to find a smaller one
        const availableFormatsThatAreLower = availableFormats.filter(format => sizeToIndex[format] < sizeIndex);

        if (availableFormatsThatAreLower.length > 0) {
            const highestAvailableFormatThatIsLower = availableFormatsThatAreLower.sort((a, b) => sizeToIndex[b] - sizeToIndex[a])[0];

            // console.log(`Returning image ${image.name} with highest available format that is lower then request ${highestAvailableFormatThatIsLower}`)

            return `${baseUrl}${image.formats[highestAvailableFormatThatIsLower].url}`;
        }

        //we don't have any smaller ones, so we will just return the original image
        // console.log(`Returning image ${image.name} with original image`)
        return `${baseUrl}${image.url}`;
    }, []);
    return { getImageUrl }
}