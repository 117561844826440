import { useIntl } from "react-intl";
import logo from "../../assets/logo.png";
import translations from "../../translations/constants";
import { Link } from "../Link";
import { Text } from "../Text";

type Props = {
    isFooterLogo?: boolean;
};

export const NavbarLogo = (props: Props) => {
    const intl = useIntl();

    return (
        <Link
            to="/"
            className="w-full flex-auto group text-black transition-colors duration-300 transform dark:text-gray-200"
        >
            <div className="flex flex-col items-center">
                <img className="w-auto h-6 sm:h-7" src={logo} alt="Finn Tegeler logo" />
                {!props.isFooterLogo && (
                    <span className="text-s w-full text-center font-thin">
                        <span className="group-hover:text-primary-default dark:group-hover:text-primary-light transition-colors duration-300 transform">
                            {intl.formatMessage(translations.components.menu.logoSubtitlePartOne)}
                        </span>
                        <span className="group-hover:text-secondary-default dark:group-hover:text-secondary-light transition-colors duration-300 transform">
                            {intl.formatMessage(translations.components.menu.logoSubtitlePartTwo)}
                        </span>
                    </span>
                )}

                {props.isFooterLogo && (
                    <>
                        <Text level="normal" className="mt-2">
                            &copy; {new Date().getFullYear()} Finn Tegeler
                        </Text>
                    </>
                )}
            </div>
        </Link>
    );
};
