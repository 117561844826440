import { defineMessages } from "react-intl";

export const services = defineMessages({
    servicesMetaTitle: {
        id: "components.services.meta-title",
        defaultMessage: "My Services - Event Technology Aachen Finn Tegeler",
        description: "The meta title for services"
    },
    servicesTitle: {
        id: "components.services.services-title",
        defaultMessage: "My Services",
        description: "The title of the services section"
    },
    viewButton: {
        id: "components.services.view-button",
        defaultMessage: "View My Projects",
        description: "The text of the view button"
    },
    backButton: {
        id: "components.services.back-button",
        defaultMessage: "Back to services",
        description: "The text of the back button"
    },
    serviceMetaTitle: {
        id: "components.services.service-meta-title",
        defaultMessage: "{serviceTitle} service - Event Technology Aachen Finn Tegeler",
    },
    serviceDoesNotExistMetaTitle: {
        id: "components.services.service-does-not-exist-meta-title",
        defaultMessage: "Missing service - Event Technology Aachen Finn Tegeler",
        description: "The meta title of the service does not exist page"
    },
    serviceExampleProjectsTitle: {
        id: "components.services.example-projects-title",
        defaultMessage: "Example projects",
        description: "The title of the example projects section"
    },
})