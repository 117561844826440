import { Services } from "../models/types";
import { Header } from "./Header";
import { Link } from "./Link";

type Props = {
    index: number;
    service: Services;
}

export const HomeServiceCard = (props: Props) => {
    const { service } = props;

    const url = `/services/${service.slug}`;

    return <Link to={url} className="max-w-2xl p-8 min-h-[200px] bg-white rounded-lg dark:bg-gray-800 text-center flex justify-center items-center">
        <Header level={2}>
            {service.title}
        </Header>
    </Link>;
}