import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { Header } from "../../components/Header";
import { HomeServiceCard } from "../../components/HomeServiceCard";
import useQueries from "../../hooks/useQueries";
import translations from "../../translations/constants";

export const ServicesRoute = () => {
    const intl = useIntl();

    const { allServicesQuery } = useQueries();

    return (
        <div className="my-8">
            <Helmet>
                <title>
                    {intl.formatMessage(translations.components.services.servicesMetaTitle)}
                </title>
            </Helmet>

            <Header level={1} suffix={{ content: ".", color: "primary" }} className="my-8">{intl.formatMessage(translations.components.services.servicesTitle)}</Header>

            {allServicesQuery.isSuccess && <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-12 md:grid-cols-2 xl:grid-cols-3 ">
                {allServicesQuery.data.map((service, index) => <HomeServiceCard key={service.id} index={index} service={service} />)}
            </div>}
        </div>
    );
};
