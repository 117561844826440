import { PropsWithChildren } from "react";

type Props = {
    level: "larger" | "normal";
    className?: string;
}

export const Text = (props: Props & PropsWithChildren) => {

    return <p className={`leading-relaxed tracking-wide text-gray-800 dark:text-gray-200 ${props.level === "normal" ? "text-base" : "text-lg"} ${props.className}`}>
        {props.children}
    </p>
}