import { useEffect, useMemo } from "react";
import { IntlProvider } from "react-intl";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { ConsentBanner } from "./components/ConsentBanner";
import { Footer } from "./components/Footer";
import { Navbar } from "./components/Navbar/index";
import { usePreloadQueries } from "./hooks/usePreloadQueries";

function App() {
    const params = useParams();
    const allowedLanguages = useMemo(() => ["en", "de"], []);
    const language = (allowedLanguages.includes(params.lang || "de") ? params.lang : "de") || "de";
    const location = useLocation();

    const navigate = useNavigate();

    usePreloadQueries();

    useEffect(() => {
        setTimeout(() => {
            const pageTitle = document.getElementsByTagName("title")[0].textContent;
            const pageLocation = window.location.protocol + "//" + window.location.host + location.pathname;

            // @ts-ignore
            gtag('event', 'page_view', {
                page_title: pageTitle,
                page_location: pageLocation
            });
        }, 100)
    }, [location])

    const languages = useMemo(() => {
        const loadLanguage = (code: string): Record<string, string> => {
            return require(`./translations/locales/${code}.json`);
        };

        const loadLanguages = (codes: string[]) => {
            return codes.reduce((acc, code) => {
                acc[code] = loadLanguage(code);
                return acc;
            }, {} as Record<string, Record<string, string>>);
        };
        return loadLanguages(allowedLanguages)
    }, [allowedLanguages]);

    useEffect(() => {
        if (!params.lang) {
            // redirect to the default

            const currentPath = location.pathname;

            const userLanguage = navigator.language || (navigator as any).userLanguage as string || "de-DE";
            const userLanguageCode = userLanguage.split("-")[0];

            navigate(`/${userLanguageCode}${currentPath}`)
        }

        if (params.lang && !allowedLanguages.includes(params.lang)) {
            const userLanguage = navigator.language || (navigator as any).userLanguage as string || "de-DE";
            const userLanguageCode = userLanguage.split("-")[0];

            navigate(`/${userLanguageCode}`)
        }
    })

    useEffect(() => {
        document.documentElement.lang = language;
    }, [language])

    return (
        <IntlProvider
            locale={language}
            messages={languages[language]}
            defaultLocale="de"
            onError={(err) => {
                console.warn(
                    `[IntlProvider Error ${err.name}] ${err.message}`,
                    err
                );
            }}
        >
            <div className="flex flex-col justify-between min-h-screen bg-gray-200 dark:bg-gray-700">
                <Navbar />

                {location.pathname.length <= 4 && <Outlet />}
                {location.pathname.length > 4 && <div className="container below_lg:mt-16 px-8 py-8 mx-auto lg:px-6 grow">
                    <Outlet />
                </div>}

                <Footer />

                <ConsentBanner />
            </div>
        </IntlProvider>


    );
}

export default App;
