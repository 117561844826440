import { useIntl } from "react-intl";
import { Link } from "../../../components/Link";
import { ProjectPreview } from "../../../components/ProjectPreview";
import useQueries from "../../../hooks/useQueries";
import translations from "../../../translations/constants";

export const FourZeroFourRoute = () => {
    const intl = useIntl();

    const { interestingProjectsQuery } = useQueries();

    return (
        <section className="bg-white dark:bg-gray-900 ">
            <div className="container min-h-screen px-6 py-12 mx-auto lg:flex lg:items-center lg:gap-12">
                <div className="wf-ull lg:w-1/2">
                    <p className="text-sm font-medium text-primary-default">{intl.formatMessage(translations.components.error.error404Title)}</p>
                    <h1 className="mt-3 text-2xl font-semibold text-gray-800 dark:text-white md:text-3xl">{intl.formatMessage(translations.components.error.error404Subtitle)}</h1>
                    <p className="mt-4 text-gray-500 dark:text-gray-400">{intl.formatMessage(translations.components.error.error404Description)}</p>

                    <div className="flex items-center mt-6 gap-x-3">
                        <button onClick={() => {
                            window.history.back();
                        }} className="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto dark:hover:bg-gray-800 dark:bg-gray-900 hover:bg-gray-100 dark:text-gray-200 dark:border-gray-700">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5 rtl:rotate-180">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                            </svg>


                            <span>{intl.formatMessage(translations.components.error.error404BackButton)}</span>
                        </button>

                        <Link to="/">
                            <button className="w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-500 rounded-lg shrink-0 sm:w-auto hover:bg-blue-600 dark:hover:bg-blue-500 dark:bg-blue-600">
                                {intl.formatMessage(translations.components.error.error404HomeButton)}
                            </button>
                        </Link>
                    </div>
                </div>

                <div className="relative w-full mt-8 lg:w-1/2 lg:mt-0">
                    <h2 className="text-2xl font-semibold tracking-wide text-gray-800 dark:text-gray-200 lg:text-4xl lg:mb-4 first-letter:text-primary-default">
                        {intl.formatMessage(translations.components.error.error404InterestingProjectTitle)}<span className="text-secondary-default">.</span>
                    </h2>

                    <div className="w-full lg:h-[32rem] h-80 md:h-96 rounded-lg">
                        {interestingProjectsQuery.isSuccess && interestingProjectsQuery.data && interestingProjectsQuery.data.length === 1 && <ProjectPreview project={interestingProjectsQuery.data[0]} mode="list" />}
                    </div>
                </div>
            </div>
        </section>

    )
};