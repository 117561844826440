import { useState } from "react";
import { Enum_Projectgroups_Type, ProjectGroups } from "../../models/types";
import { QuestionCircleIcon } from "../Icon/QuestionCircleIcon";
import { Modal } from "../Modal";
import { Text } from "../Text";

type Props = {
    group: ProjectGroups;
    onClick?: () => void;
    enableModalIcon?: boolean;
    highlight?: boolean;
};

export const ProjectGroupTag = (props: Props) => {
    const { group } = props;

    const defaultColorClass = "bg-gray-600 hover:bg-gray-500 text-gray-100";
    const primaryColorClass = "bg-primary-default hover:bg-primary-dark text-gray-100";

    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="relative inline-block group m-2">
            <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} withCancelButton title={group.title}>
                <Text level="normal">
                    {group.description}
                </Text>
            </Modal>
            <button
                className={`project-group-button px-3 flex items-center justify-between py-1 text-lg font-medium transition-colors duration-300 transform cursor-pointer rounded ${props.highlight !== undefined ? (props.highlight ? primaryColorClass : defaultColorClass) : group.type === Enum_Projectgroups_Type.Primary ? primaryColorClass : defaultColorClass}`}
                tabIndex={0}
                onClick={props.onClick ? (e) => {
                    const clickedButton = (e.target as HTMLElement).classList.contains("project-group-button");
                    if (clickedButton) props.onClick!();
                } : () => setIsOpen(true)}
            >
                {group.title} {props.enableModalIcon && <div className="w-4 h-4 ml-1" onClick={() => {
                    setIsOpen(true);
                }}>
                    <QuestionCircleIcon />
                </div>}
            </button>
        </div>
    );
};
