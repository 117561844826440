import { defineMessages } from "react-intl";

const contact = defineMessages({
    contactMetaTitle: {
        id: "components.contact.meta-title",
        defaultMessage: "Contact Me - Event Technology Aachen Finn Tegeler",
        description: "The meta title for the contact page"
    },
    contactTitle: {
        id: "components.contact.title",
        defaultMessage: "Let's Talk About Your Event!",
        description: "The title of the contact page"
    },
    contactDescription: {
        id: "components.contact.description",
        defaultMessage: "Get in touch with me to discuss about your project and how I can help you make it a success. The easiest way to reach me is via contact form or email. You can also call me directly or ask for a callback when filling out the contact form. I am looking forward to hearing from you!",
        description: "The description of the contact page"
    },
    contactFormButton: {
        id: "components.contact.form-button",
        defaultMessage: "Open Contact Form",
        description: "The text of the contact form button"
    },
    emailButton: {
        id: "components.contact.email-button",
        defaultMessage: "Email Me",
        description: "The text of the email button"
    },
    callButton: {
        id: "components.contact.call-button",
        defaultMessage: "Call Me",
        description: "The text of the call button"
    }
})

export default contact;