import { defineMessages } from "react-intl";

export const menu = defineMessages({
    logoSubtitlePartOne: {
        id: "components.menu.logo-subtitle-one",
        defaultMessage: "Event",
        description: "The first part of the subtitle of the logo"
    },
    logoSubtitlePartTwo: {
        id: "components.menu.logo-subtitle-two",
        defaultMessage: "Technology",
        description: "The second part of the subtitle of the logo"
    },
    servicesMenuItem: {
        id: "components.menu.services-menu-item",
        defaultMessage: "Services",
        description: "The text of the services menu item"
    },
    projectsMenuItem: {
        id: "components.menu.projects-menu-item",
        defaultMessage: "Projects",
        description: "The text of the projects menu item"
    },
    contactMenuItem: {
        id: "components.menu.contact-menu-item",
        defaultMessage: "Contact",
        description: "The text of the contact menu item"
    },
    languageSelectGerman: {
        id: "components.menu.language-select-german",
        defaultMessage: "Deutsch",
        description: "The text of the german language select"
    },
    languageSelectEnglish: {
        id: "components.menu.language-select-english",
        defaultMessage: "English",
        description: "The text of the english language select"
    },
})