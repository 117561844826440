import { MouseEventHandler, PropsWithChildren } from "react";

type Props = {
    type: "default" | "outline";
    color: "primary" | "secondary" | "gray";
    fullWidth?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    className?: string;
} & PropsWithChildren;

export const Button = (props: Props) => {
    const { type, color } = props;

    const globalClasses =
        "px-6 py-2 font-medium text-center tracking-wide capitalize transition-colors duration-300 transform rounded-lg focus:outline-none border-2 cursor-pointer" + (props.fullWidth ? " w-full" : "below_lg:w-full") + " " + props.className;

    return (
        <>
            {type === "default" && (
                <>
                    {color === "primary" && (
                        <button onClick={props.onClick}
                            className={`${globalClasses} border-primary-default text-white bg-primary-default hover:bg-primary-light hover:border-primary-light`}
                        >
                            {props.children}
                        </button>
                    )}
                    {color === "secondary" && (
                        <button onClick={props.onClick}
                            className={`${globalClasses} border-secondary-default text-white bg-secondary-default hover:bg-secondary-light hover:border-secondary-light`}
                        >
                            {props.children}
                        </button>
                    )}
                    {color === "gray" && (
                        <button onClick={props.onClick}
                            className={`${globalClasses} border-gray-500 text-white bg-gray-500 hover:bg-gray-400 hover:border-gray-400`}
                        >
                            {props.children}
                        </button>
                    )}
                </>
            )}

            {type === "outline" && (
                <>
                    {color === "primary" && (
                        <button onClick={props.onClick}
                            className={`${globalClasses} border-primary-default text-primary-default hover:text-primary-light dark:hover:text-white dark:text-white bg-transparent hover:border-primary-light`}
                        >
                            {props.children}
                        </button>
                    )}
                    {color === "secondary" && (
                        <button onClick={props.onClick}
                            className={`${globalClasses} border-secondary-default text-secondary-default hover:text-secondary-light dark:hover:white dark:text-white bg-transparent hover:border-secondary-light`}
                        >
                            {props.children}
                        </button>
                    )}
                    {color === "gray" && (
                        <button onClick={props.onClick}
                            className={`${globalClasses} border-gray-500 text-gray-500 bg-transparent hover:border-gray-400 hover:text-gray-400`}
                        >
                            {props.children}
                        </button>
                    )}
                </>
            )}
        </>
    );
};
