import { useQuery } from "@tanstack/react-query";
import strapi from "../api";
import { ProjectGroups, Projects, Services } from "../models/types";
import { useLanguage } from "./useLanguage";

const useQueries = () => {
    const [language] = useLanguage();

    const allServicesQuery = useQuery({
        queryKey: [`${language}_services`],
        queryFn: async () => {
            return await strapi.find<Services[]>("services", {
                _locale: language,
                _sort: "priority:desc",
            });
        },
    });

    const allProjectsQuery = useQuery({
        queryKey: [`${language}_projects`],
        queryFn: async () => {
            return await strapi.find<Projects[]>("projects", {
                _locale: language,
                _sort: "priority:desc"
            });
        },
    });

    const allProjectGroupsQuery = useQuery({
        queryKey: [`${language}_projectGroups`],
        queryFn: async () => {
            return await strapi.find<ProjectGroups[]>("project-groups", {
                _locale: language,
            })
        },
    });

    const interestingProjectsQuery = useQuery({
        queryKey: ["project", "interesting"],
        queryFn: async () => {
            return await strapi.find<Projects[]>("projects", {
                _limit: 3,
                _locale: language,
                _sort: "priority:desc",
            })
        },
        enabled: false,
    });

    const limitedServicesQuery = useQuery({
        queryKey: ["service", "limited", language],
        queryFn: async () => {
            return await strapi.find<Services[]>("services", {
                _limit: 3,
                _locale: language,
                _sort: "priority:desc",
            })
        },
        enabled: true,
    });

    return {
        allServicesQuery,
        allProjectsQuery,
        allProjectGroupsQuery,
        interestingProjectsQuery,
        limitedServicesQuery,
    }
}

export default useQueries;