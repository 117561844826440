import { Enum_Projectgroups_Type, ProjectGroups } from "../../models/types";
import { ProjectGroupTag } from "./ProjectGroupTag";

type Props = {
    groups: ProjectGroups[];
};

export const ProjectGroupTags = (props: Props) => {
    const { groups } = props;

    return (
        <div className="-m-1">
            {groups.sort((a, b) => {
                if (a.type === Enum_Projectgroups_Type.Primary && b.type !== Enum_Projectgroups_Type.Primary) return -1;
                if (a.type !== Enum_Projectgroups_Type.Primary && b.type === Enum_Projectgroups_Type.Primary) return 1;

                return a.title.localeCompare(b.title);
            })
                .map((group) => (
                    <ProjectGroupTag key={group.id} group={group} />
                ))}
        </div>
    );
};
