import { useIntl } from "react-intl";
import translations from "../../../translations/constants";

type Props = {
    isOpen: boolean;
    setOpen: (isOpen: boolean) => void;

    onSelectLanguage: (code: "de" | "en") => void;
};

export const SelectLanguageButtonDropdown = (props: Props) => {
    const intl = useIntl();

    const languages: {
        code: "de" | "en";
        flag: string;
        title: string;
    }[] = [
            {
                code: "de",
                flag: "de",
                title: intl.formatMessage(translations.components.menu.languageSelectGerman),
            },
            {
                code: "en",
                flag: "gb",
                title: intl.formatMessage(translations.components.menu.languageSelectEnglish),
            },
        ];

    const onSelectLanguage = (code: (typeof languages)[number]["code"]) => {
        props.setOpen(false);
        props.onSelectLanguage(code);
    };

    return (
        <>
            {props.isOpen && (
                <div className="absolute right-0 z-20 py-2 mt-2 overflow-hidden origin-top-right bg-white rounded-md shadow-xl dark:bg-gray-800">
                    {languages.map((lang) => (
                        <button
                            key={lang.code}
                            onClick={() => onSelectLanguage(lang.code)}
                            className="flex items-center p-3 text-sm capitalize bg-white dark:bg-gray-900 dark:border-gray-700 dark:hover:bg-gray-800  hover:bg-gray-100 duration-300 transition-colors  text-gray-600 dark:text-gray-300"
                        >
                            <span className={`fi fi-${lang.flag} mx-1`} />

                            <span className="mx-1">{lang.title}</span>
                        </button>
                    ))}
                </div>
            )}
        </>
    );
};
