import { Enum_Services_Icon } from "../../models/types";
import { CheckIcon } from "../Icon/CheckIcon";
import { CheckListIcon } from "../Icon/CheckListIcon";
import { CrossIcon } from "../Icon/CrossIcon";
import { ExclamationFilledIcon } from "../Icon/ExclamationFilledIcon";
import { FireIcon } from "../Icon/FireIcon";
import { FlashFilledIcon } from "../Icon/FlashFilledIcon";
import { MoonIcon } from "../Icon/MoonIcon";
import { PuzzlePieceIcon } from "../Icon/PuzzlePieceIcon";
import { QuestionCircleIcon } from "../Icon/QuestionCircleIcon";
import { SettingsIcon } from "../Icon/SettingsIcon";
import { SparkleIcon } from "../Icon/SparkleIcon";
import { SunIcon } from "../Icon/SunIcon";

type Props = {
    icon?: Enum_Services_Icon;
    index: number;
    withUnknownIcon?: boolean;
    size?: "normal" | "large";
}

export const ServiceCardIcon = (props: Props) => {
    const oddColor = "text-primary-default bg-pink-200 dark:text-pink-200 dark:bg-primary-default";
    const evenColor = "text-secondary-default bg-sky-100 dark:text-sky-100 dark:bg-secondary-default";

    if (props.withUnknownIcon) {
        return <span className={`inline-block p-2 animate-pulse  bg-gray-200 dark:bg-gray-700 rounded-xl md:mx-4`}>
            <div className={props.size === "large" ? "w-8 h-8" : "w-6 h-6"} />
        </span>
    }

    return <span className={`inline-block p-2 rounded-xl ${props.index % 2 === 0 ? oddColor : evenColor}`}>
        <div className={props.size === "large" ? "w-8 h-8" : "w-6 h-6"}>
            {props.icon === Enum_Services_Icon.Check && <CheckIcon />}
            {props.icon === Enum_Services_Icon.CheckList && <CheckListIcon />}
            {props.icon === Enum_Services_Icon.Cross && <CrossIcon />}
            {props.icon === Enum_Services_Icon.ExclamationFilled && <ExclamationFilledIcon />}
            {props.icon === Enum_Services_Icon.Fire && <FireIcon />}
            {props.icon === Enum_Services_Icon.FlashFilled && <FlashFilledIcon />}
            {props.icon === Enum_Services_Icon.Moon && <MoonIcon />}
            {props.icon === Enum_Services_Icon.PuzzlePiece && <PuzzlePieceIcon />}
            {props.icon === Enum_Services_Icon.QuestionCircle && <QuestionCircleIcon />}
            {props.icon === Enum_Services_Icon.Settings && <SettingsIcon />}
            {props.icon === Enum_Services_Icon.Sparkle && <SparkleIcon />}
            {props.icon === Enum_Services_Icon.Sun && <SunIcon />}
        </div>
    </span>;
}