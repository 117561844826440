import { IconProps } from ".";

export const CheckIcon = (props: IconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" {...props}>
            <path 
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={props.strokeWidth || 2}
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
        </svg>
    );
};
