import { create } from "zustand";
import { persist } from "zustand/middleware";

type GlobalStore = {
    previousProjectGroupSlug?: string;
};

export const useGlobalStore = create<GlobalStore>()(
    persist(
        (set) => ({
        }),
        {
            name: "global",
        }
    )
);