import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { ContactCard } from "../../components/ContactCard";
import { Header } from "../../components/Header";
import { Text } from "../../components/Text";
import translations from "../../translations/constants";

export const ContactRoute = () => {
    const intl = useIntl();

    return (
        <div className="flex flex-col gap-8 min-h-full below_lg:justify-center lg:mt-32">
            <Helmet>
                <title>{intl.formatMessage(translations.components.contact.contactMetaTitle)}</title>
                <meta name="robots" content="noindex" />
            </Helmet>

            <Header level={1} suffix={{ content: "!", color: "primary" }}>
                {intl.formatMessage(translations.components.contact.contactTitle)}
            </Header>

            <Text level={"larger"} className="max-w-screen-lg">
                {intl.formatMessage(translations.components.contact.contactDescription)}
            </Text>

            <div className="max-w-screen-lg">
                <ContactCard mode="route" />
            </div>
        </div>
    );
};
