import { ServiceCardIcon } from "../ServiceCard/ServiceCardIcon";
import { AbstractHeaderSkeleton } from "./Abstract/AbstractHeaderSkeleton";
import { AbstractTextLineSkeleton } from "./Abstract/AbstractTextLineSkeleton";

type Props = {
    type: "home";
}

export const ServiceSkeleton = (props: Props) => {
    return <div className="max-w-2xl p-4 bg-white rounded-lg dark:bg-gray-800 mb-4">
        <div className="flex flex-row gap-4">
            <div className="w-[10%]">
                <ServiceCardIcon index={0} withUnknownIcon size="large" />
            </div>

            <div className="w-[90%]">
                <div className="mt-2">
                    <AbstractHeaderSkeleton length={40} />

                    <div className="below_lg:hidden">
                        <AbstractTextLineSkeleton length={48} />
                        <AbstractTextLineSkeleton length={40} />
                        <AbstractTextLineSkeleton length={52} />
                    </div>
                </div>

                <div className="flex items-center justify-between mt-4">
                    <AbstractTextLineSkeleton length={40} />
                </div>
            </div>
        </div>
    </div>;
}
