
import { useIntl } from "react-intl";
import translations from "../../translations/constants";
import { DarkModeButton } from "../Button/DarkModeButton";
import { SelectLanguageButton } from "../Button/SelectLanguageButton";
import { Link } from "../Link";

type Props = {
    mode: "mobile" | "default";
};

export const NavbarMenu = (props: Props) => {
    const intl = useIntl();

    if (props.mode === "mobile") {
        return (
            <>
                <Link
                    to="/services"
                    className="my-2 font-light text-black transition-colors duration-300 transform dark:text-gray-200 hover:text-secondary-default dark:hover:text-secondary-light mx-4 last:mr-0"
                >
                    {intl.formatMessage(translations.components.menu.servicesMenuItem)}
                </Link>

                <Link
                    to="/projekt"
                    className="my-2 font-light text-black transition-colors duration-300 transform dark:text-gray-200 hover:text-secondary-default dark:hover:text-secondary-light mx-4 last:mr-0"
                >
                    {intl.formatMessage(translations.components.menu.projectsMenuItem)}
                </Link>

                <Link
                    to="/contact"
                    className="my-2 font-light text-black transition-colors duration-300 transform dark:text-gray-200 hover:text-secondary-default dark:hover:text-secondary-light mx-4 last:mr-0"
                >
                    {intl.formatMessage(translations.components.menu.contactMenuItem)}
                </Link>

                <SelectLanguageButton />

                <DarkModeButton />
            </>
        );
    } else {
        return (
            <>
                <Link
                    to="/services"
                    className="text-center my-2 font-light text-xl text-black dark:text-gray-200 mx-4"
                >
                    {intl.formatMessage(translations.components.menu.servicesMenuItem)}
                </Link>

                <Link
                    to="/projekt"
                    className="text-center my-2 font-light text-xl text-black dark:text-gray-200 mx-4"
                >
                    {intl.formatMessage(translations.components.menu.projectsMenuItem)}
                </Link>

                <Link
                    to="/contact"
                    className="text-center my-2 font-light text-xl text-black dark:text-gray-200 mx-4"
                >
                    {intl.formatMessage(translations.components.menu.contactMenuItem)}
                </Link>

                <div className="flex flex-row justify-end gap-2">
                    <SelectLanguageButton />

                    <DarkModeButton />
                </div>
            </>
        );
    }
};
