
import { useIntl } from "react-intl";
import logo from "../../assets/logo.png";
import translations from "../../translations/constants";
import { Link } from "../Link";

export const Footer = () => {
    const intl = useIntl();

    return (
        <footer className="bg-white dark:bg-gray-900">
            <div className="container px-6 py-2 md:py-4 mx-auto flex items-center justify-between md:flex-row flex-col">
                <Link to="/">
                    <img src={logo} className="h-8 w-auto m-2 cursor-pointer" alt="Finn Tegeler logo" />
                </Link>

                <div className="flex flex-col items-center">
                    <span className="text-gray-800 dark:text-gray-200">
                        &copy; {new Date().getFullYear()} Finn Tegeler
                    </span>

                    <span>
                        <Link to={"/imprint"} className="text-gray-600 dark:text-gray-400">{intl.formatMessage(translations.components.generic.imprint)}</Link>
                        <span className="text-gray-800 dark:text-gray-200">&ensp;·&ensp;</span>
                        <Link to={"/privacy"} className="text-gray-600 dark:text-gray-400">{intl.formatMessage(translations.components.generic.privacy)}</Link>
                    </span>
                </div>
            </div>
        </footer>
    );
};
