import "core-js/stable";
//needs to be first

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import ReactDOM from "react-dom/client";
import { AppRouter } from "./routes";
import * as serviceWorker from "./serviceWorker";
import "./styles/tailwind.css";
import "/node_modules/flag-icons/css/flag-icons.min.css";

export const rootQueryClient = new QueryClient({
    defaultOptions: { queries: { refetchOnWindowFocus: false, staleTime: Infinity, gcTime: 1000 * 60 * 15 } },
});

ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <QueryClientProvider client={rootQueryClient}>
            <AppRouter />
            {/* <ReactQueryDevtools /> */}
        </QueryClientProvider>
    </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
