import { defineMessages } from "react-intl";

const generic = defineMessages({
    cancel: {
        id: "components.generic.cancel",
        defaultMessage: "Cancel",
        description: "The text of the cancel button"
    },
    close: {
        id: "components.generic.close",
        defaultMessage: "Close",
        description: "The text of the close button"
    },
    imprint: {
        id: "components.generic.imprint",
        defaultMessage: "Imprint",
        description: "The text of the imprint link"
    },
    privacy: {
        id: "components.generic.privacy",
        defaultMessage: "Privacy",
        description: "The text of the privacy link"
    }
})

export default generic;