import contact from "./contact";
import { error } from "./error";
import generic from "./generic";
import { home } from "./home";
import { menu } from "./menu";
import { projects } from "./projects";
import { services } from "./services";

const components = {
    projects, 
    services, 
    home, 
    error, 
    menu, 
    generic,
    contact,
};

export default components;