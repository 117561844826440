import { useState } from "react";
import { useLanguage } from "../../../hooks/useLanguage";
import { styles } from "../../../styles/constants";
import { SelectLanguageButtonDropdown } from "./SelectLanguageButtonDropdown";

export const SelectLanguageButton = () => {
    const [language, setLanguage] = useLanguage();

    const currentFlag = language === "de" ? "de" : "gb";

    const [isOpen, setIsOpen] = useState(false);

    const onClick = () => {
        setIsOpen(!isOpen);
    };

    const onSetLanguage = async (code: "de" | "en") => {
        setLanguage(code);
    }

    return (
        <>
            <div className="relative inline-block lg:mx-2 lg:my-2">
                <button
                    onClick={onClick}
                    className={`relative z-10 flex items-center h-10 p-2 text-sm border rounded-lg ${styles.secondaryActionButtons}`}
                    aria-label="Sprache auswählen"
                >
                    <span className={`mx-1 fi fi-${currentFlag}`}></span>
                    <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12 15.713L18.01 9.70299L16.597 8.28799L12 12.888L7.40399 8.28799L5.98999 9.70199L12 15.713Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                </button>
                <SelectLanguageButtonDropdown setOpen={setIsOpen} isOpen={isOpen} onSelectLanguage={onSetLanguage} />
            </div>
        </>
    );
};
